import actions from './actions';

describe('actions', () => {
  describe('pollingSystemTime', () => {
    it('should call setInternal once w/o pollingId', async () => {
      const original = global.setInterval;
      global.setInterval = jest.fn();
      const state = { pollingId: -1 };
      const dispatch = jest.fn();

      actions.pollingSystemTime({ state, dispatch });
      const fn = global.setInterval.mock.calls[0][0];
      await fn();

      expect(global.setInterval.mock.calls[0][1]).toBe(60000);
      global.setInterval = original;
    });

    it('should do nothing w/ pollingId', async () => {
      const original = global.setInterval;
      global.setInterval = jest.fn();
      const state = { pollingId: 100 };
      const dispatch = jest.fn();

      actions.pollingSystemTime({ state, dispatch });
      expect(global.setInterval.mock.calls).toEqual([]);
      global.setInterval = original;
    });
  });
});
